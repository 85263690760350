<template>
  <div>
    <!-- <button name="button" @click="addMarker">
              Add a marker
          </button> 
      <br />-->

    <!-- <b-table class="col-12">
              <tr>
                  <th>Marker</th>
                  <th>Latitude</th>
                  <th>Longitude</th>
                  <th>Tooltip</th>
                  <th>Is Draggable ?</th>
                  <th>Is Visible ?</th>
                  <th>Location</th>
                  <th>Remove</th>
              </tr>
              <tr v-for="(item, index) in markers" :key="index">D
                  <td>{{ (index + 1) }}</td>
                  <td>
                      <input v-model.number="item.position.lat" type="number">
                  </td>
                  <td>
                      <input v-model.number="item.position.lng" type="number">
                  </td>
                  <td>
                      <input v-model="item.tooltip" type="text">
                  </td>
                  <td style="text-align: center">
                      <input v-model="item.draggable" type="checkbox">
                  </td>
                  <td style="text-align: center">
                      <input v-model="item.visible" type="checkbox">
                  </td>
                  <td style="text-align: center">
                      <b-button variant="outline-primary" @click="navigatMarker(item)">
                          <feather-icon icon="CrosshairIcon" />
                      </b-button>
                  </td>
                  <td style="text-align: center">
                      <b-button variant="outline-danger" type="button" @click="removeMarker(index)">
                          <feather-icon icon="Trash2Icon" />
                      </b-button>
                  </td>
              </tr>
          </b-table> -->

    <b-nav class="navbar navbar-light shadow mb-1 d-flex justify-content-between bd-highlight flex-wrap">
      <div class="bd-highlight">
        <b-button variant="danger" @click="() => $router.go(-1)"> กลับ </b-button>
      </div>
    </b-nav>
    <l-map :zoom="zoom" :center="center">
      <l-tile-layer :url="url" :attribution="attribution" />
      <!-- Map control -->
      <!-- <l-control class="example-custom-control">
          <div class="d-flex bg-white py-1">
            <div class="mx-1">
              <b-form-checkbox @change="filterMap('inform')" v-model="handletogglemap" value="inform" class="custom-control-danger">
                <span class="text-danger">แจ้งเหตุ</span>
              </b-form-checkbox>
            </div>
            <div class="mx-1">
              <b-form-checkbox @change="filterMap('maintenance')" v-model="handletogglemap" value="maintenance" class="custom-control-warning">
                <span class="text-warning">เเจ้งซ่อม</span>
              </b-form-checkbox>
            </div>
            <div class="mx-1">
              <b-form-checkbox @change="filterMap('appeal')" v-model="handletogglemap" value="appeal" class="custom-control-info">
                <span class="text-info">ร้องเรียน</span>
              </b-form-checkbox>
            </div>
          </div>
        </l-control> -->
      <!-- <l-control position="bottomleft">
          <b-button @click="addMarker"> Add a marker </b-button>
        </l-control> -->

      <!-- marker -->
      <div>
        <l-marker v-for="(marker, index) in markers" :key="index" :draggable="false" :lat-lng.sync="marker.gps" :icon="marker.icon">
          <!-- <l-icon>
              <div v-if="marker.type === 'inform'">
                <b-img src="./image/marker/inform.png" height="32" />
              </div>
              <div v-show="marker.type === 'maintenance'">
                <b-img src="./image/marker/maintenance.png" height="32" />
              </div>
              <div v-show="marker.type === 'appeal'">
                <b-img src="./image/marker/appeal.png" height="32" />
              </div>
            </l-icon> -->

          <l-popup >
            <div v-show="urlImage.length != 0">
              <div class="item-img" v-if="urlImage.length">
                <swiper :options="swiperOptions" class="swiper-lazy-loading">
                  <swiper-slide v-for="(image, index) in urlImage" :key="index">
                    <b-img :src="image" fluid center class="card-img-top mw-100" style="max-width: 100%; height: 300px; object-fit: cover" />
                    <div v-if="index != 0">
                      <div class="swiper-lazy-preloader swiper-lazy-preloader-white" />
                    </div>
                  </swiper-slide>
                  <div slot="pagination" class="swiper-pagination" />
                  <div slot="button-next" class="swiper-button-next swiper-button-white" />
                  <div slot="button-prev" class="swiper-button-prev swiper-button-white" />
                </swiper>
              </div>
              <div v-else>
                <div class="item-img" style="max-width: 100%; height: 300px; object-fit: cover">
                  <b-overlay :show="true" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm" no-wrap />
                  <!-- <b-img src="https://zosepcar.cl/images/cargando.gif" class="card-img-top" style="width: 100%; height: 100%" /> -->
                </div>
              </div>
            </div>
            <p style="width: 100vh">{{ marker.title }}</p>

            <div v-show="marker.link">
              <a :href="marker.link" target="_blank"> https://www.google.com/</a>
            </div>
          </l-popup>
          <!-- <l-tooltip :options="{ permanent: true, interactive: true }"> Marker {{ marker.num + 1 }} {{ marker.title }} </l-tooltip> -->
        </l-marker>
      </div>

      <!-- วงกลม -->
      <!-- <l-circle :lat-lng="circle.center" :radius="circle.radius" :color="circle.color" /> -->
    </l-map>
  </div>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

  import 'swiper/css/swiper.css';
  import 'vue-good-table/dist/vue-good-table.css';
  import { LMap, LTileLayer, LCircle, LMarker, LPopup, LControl, LTooltip, LIcon } from 'vue2-leaflet';
  import L from 'leaflet';
  import { Icon } from 'leaflet';
  delete Icon.Default.prototype._getIconUrl;
  Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  });

  import 'leaflet/dist/leaflet.css';

  import dayjs from 'dayjs';
  import 'dayjs/locale/th';
  import utc from 'dayjs/plugin/utc';
  import timezone from 'dayjs/plugin/timezone';

  dayjs.extend(utc);
  dayjs.extend(timezone);
  const buddhistEra = require('dayjs/plugin/buddhistEra');
  dayjs.extend(buddhistEra);
  const relativeTime = require('dayjs/plugin/relativeTime');
  dayjs.extend(relativeTime);
  export default {
    name: 'Reportofincident',

    components: {
      Swiper,
      SwiperSlide,
      LMap,
      LTileLayer,
      LCircle,
      LMarker,
      LPopup,
      LTooltip,
      LControl,
      LIcon,
    },

    data() {
      return {
        // map data
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        mapimage: '',
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',

        zoom: 15,
        center: [0, 0],

        blueIcon: L.icon({
          iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
          shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          shadowSize: [41, 41],
        }),
        redIcon: L.icon({
          iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
          shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          shadowSize: [41, 41],
        }),
        orangeIcon: L.icon({
          iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png',
          shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          shadowSize: [41, 41],
        }),
        markers: [],
        urlImage: [],
        swiperOptions: {
          lazy: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: false,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        },
      };
    },

    async created() {
      console.log(this.$store.getters.getStates);
      // console.log(this.$route);
      if (this.$store.getters.getStates.length == 0) {
        this.$router.go(-1);
      } else {
        const { gps, urlImage, title } = this.$store.getters.getStates[0];
        console.log(gps);
        this.urlImage = urlImage;
        this.center = [gps.latitude, gps.longitude];
        this.markers.push({
          title: title,
          gps: this.center,
        });
        console.log(this.markers);
      }
    },

    methods: {
      formatToThaiTime(date) {
        // เปลี่ยนวันที่ไทย
        return dayjs.tz(date, 'Asia/Bangkok').format('DD-MM-BBBB HH:ss');
      },
      async getDataTable() {
        //console.log('userId');
        this.$store
          .dispatch('getData', `ticket/${JSON.parse(localStorage.getItem('profile_visitor')).userId}?uId=${JSON.parse(localStorage.getItem('profile_visitor')).uId}`)
          .then(async (res) => {
            this.markers = await res.message.result.map((el, index) => ({
              ...el,
              num: index,
              gps: {
                lat: el.gps.latitude,
                lng: el.gps.longitude,
              },
              draggable: false,
              visible: el.status === 'open' ? true : false,
            }));
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      },

      addMarker() {
        const newMarker = {
          position: { lat: this.lat, lng: this.lng },
          draggable: true,
          visible: true,
        };
        this.markers.push(newMarker);
      },
      centerUpdate(center) {
        const { lat, lng } = center;
        this.lat = lat;
        this.lng = lng;
      },
      navigatMarker(item) {
        this.center = [item.position.lat, item.position.lng];
      },
      removeMarker(index) {
        this.$swal({
          title: 'Are you sure?',
          // text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Your file has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
            const remove = this.markers.filter((item) => item.num !== index);
            this.markers = remove;
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .vue2leaflet-map {
    &.leaflet-container {
      height: 65vh;
      width: 100%;
    }
  }
  .leaflet-popup-content-wrapper .leaflet-popup-content {
    width: 40vh !important;
  }
</style>
